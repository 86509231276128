<template>
  <page>
    <template #navigation>
      <div class="flex space-x-2 items-center">
        <router-link
          :to="backLink"
          class="
            rounded
            inline-flex
            items-center
            justify-center
            min-w-min
            uppercase
            tracking-wide
            whitespace-nowrap
            h-8
            w-8
            text-sm
            border border-blue-900
            text-blue-700
            hover:bg-blue-700 hover:text-white
            bg-transparent
          "
          small
          ><icon face="mdiChevronLeft"
        /></router-link>
        <span
          class="
            text-xs text-green-700
            uppercase
            whitespace-nowrap
            hidden
            xs:block
          "
          >{{ $t(isCreate ? 'estates.create' : 'estates.edit') }}</span
        >
      </div>
    </template>
    <div v-if="form" class="max-w-5xl mx-4 md:mx-auto">
      <tab-nav :tabs="tabs" v-model="currentTabId" />
      <div class="bg-white border p-8 shadow border-gray-300 rounded">
        <div v-show="currentTabId === 'general'">
          <div v-for="item in editHeaders" :key="item.id">
            <dynamic-field
              :heading="item"
              :value="getEstateValue(item)"
              @input="(event) => setEstateValue(event, item)"
              @changed="clearCurrentError(item)"
              :error="form.i18n(item.id)"
            />
          </div>
        </div>
        <div v-show="currentTabId === 'tags'">
          <draggable
            draggable=".item"
            handle=".item-handle"
            ghost-class="ghost"
            class="
              bg-white
              w-full
              rounded
              border border-gray-300
              pb-2
              px-1
              flex flex-row flex-wrap
            "
            v-model="formTags"
          >
            <tag
              class="text-xl select-none mx-1 mt-2 item"
              v-for="item in formTags"
              :key="item.header.id"
            >
              <div class="bg-blue-100 text-blue-800 cursor-move item-handle">
                {{ item.header.name }}
              </div>
              <a
                class="bg-blue-800 text-blue-100 cursor-pointer hover:underline"
                @click="onEditTaggable(item)"
                >{{ getTaggableDisplay(item) }}</a
              >
            </tag>
            <tag v-if="formTags.length === 0" class="text-xl select-none mt-2">
              <div class="text-gray-300">
                {{ $t('general.pleaseSelect') }}
              </div>
            </tag>
          </draggable>
          <div class="flex flex-row flex-wrap -mx-1">
            <div
              v-for="item in remainedTaggableHeaders"
              :key="item.id"
              class="w-1/3 md:w-1/4 lg:w-1/6 px-1 mt-4"
            >
              <button
                class="
                  w-full
                  bg-blue-100
                  text-blue-800
                  hover:bg-blue-200
                  rounded
                  text-xs
                  h-12
                  whitespace-nowrap
                  overflow-hidden overflow-ellipsis
                "
                @click="onAddTaggable(item)"
              >
                {{ item.name }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-between items-center my-4">
        <div class="flex items-center">
          <confirm icons-only @click="onDelete" v-if="!isCreate" />
        </div>
        <div class="flex items-center space-x-2">
          <action variant="link" @click="goBack">{{
            $t('actions.cancel')
          }}</action>
          <action
            variant="primary"
            @click="onSaveItem"
            class="mx-1"
            :disabled="isEstateUpdating"
            :loading="isEstateUpdating"
            >{{ $t('actions.save') }}</action
          >
        </div>
      </div>
    </div>
    <modal
      v-if="tagInput"
      @close="tagInput = null"
      size="medium"
      :title="tagInput.header.name"
    >
      <form @submit.prevent="saveTaggableAttribute">
        <div class="mb-4">
          <div
            v-if="tagInput.header.type === 'B'"
            class="rounded bg-gray-50 md:text-2xl flex"
          >
            <button
              type="button"
              @click="tagInput.value = false"
              class="flex items-center justify-center rounded-l py-2 w-1/2"
              :class="{
                'bg-transparent text-red-300': !!tagInput.value,
                'bg-red-700 text-white': !tagInput.value
              }"
            >
              {{ $t('bool.no') }}
            </button>
            <button
              type="button"
              @click="tagInput.value = true"
              class="flex items-center justify-center rounded-r py-2 w-1/2"
              :class="{
                'bg-transparent text-greeun-300': !tagInput.value,
                'bg-green-700 text-white': !!tagInput.value
              }"
            >
              {{ $t('bool.yes') }}
            </button>
          </div>
          <input
            v-else
            class="
              block
              w-full
              text-center
              border border-gray-400
              rounded
              p-4
              focus:outline-none
            "
            id="taggable-input"
            autocomplete="off"
            :type="tagInput.header.type === 'N' ? 'number' : 'text'"
            v-model="tagInput.value"
          />
        </div>
        <div class="flex flex-row justify-between mt-8 items-center space-x-1">
          <action-icon
            face="mdiDeleteForeverOutline"
            variant="danger"
            type="button"
            @click="removeTaggableAttribute"
          />
          <action
            variant="primary"
            type="submit"
            :disabled="isInvalidTaggableAttribute"
            >{{ $t('actions.save') }}</action
          >
        </div>
      </form>
    </modal>
  </page>
</template>

<script>
import draggable from 'vuedraggable'
import { mapState, mapActions } from 'vuex'
import { Form, getProcHeaders, getEmptyHeaderValue } from '@/services'

const _wait = () =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(true)
    }, 250)
  })

export default {
  components: { draggable },
  data() {
    return {
      form: null,
      currentTabId: 'general',
      formTags: [],
      tagInput: null
    }
  },
  computed: {
    ...mapState({
      loaded: (state) => state.loaded,
      isBusy: (state) =>
        state.settings.loading ||
        state.settings.updating ||
        state.headers.loading ||
        state.headers.updating,
      settings: (state) => state.settings,
      headers: (state) => state.headers,
      estates: (state) => state.estates,
      isEstateUpdating: (state) => state.estates.updating
    }),
    tabs() {
      return [
        { id: 'general', i18n: 'tabs.general' },
        { id: 'tags', i18n: 'tabs.tags' }
      ]
    },
    isCreate() {
      return !this.$route.params.id
    },
    estateId() {
      return this.isCreate ? null : this.$route.params.id
    },
    procHeaders() {
      if (!this.loaded) {
        return []
      }
      const _settings = this.settings.items.find(
        (item) => item.name === 'estates'
      )
      const _headers = this.headers?.items.find(
        (item) => item.name === 'estates'
      )
      return getProcHeaders(_settings.items, _headers?.items)
    },
    fillableHeaders() {
      return this.procHeaders.filter((h) => h.fill !== false)
    },
    editHeaders() {
      return this.procHeaders.filter((h) => h.fill !== false && h.tag !== true)
    },
    taggableHeaders() {
      return this.procHeaders.filter((h) => h.fill !== false && !!h.tag)
    },
    remainedTaggableHeaders() {
      return this.taggableHeaders
        .slice(0)
        .filter((item) => !this.formTags.find((v) => v.header.id === item.id))
    },
    hasErrors() {
      if (!this.form) {
        return false
      }
      return Object.keys(this.form.errors).length > 0
    },
    isInvalidTaggableAttribute() {
      if (!this.tagInput) {
        return true
      }

      return !this.notEmpty(this.tagInput)
    },
    backLink() {
      return this.isCreate ? '/estates' : `/estates/${this.estateId}/view`
    }
  },
  mounted() {
    if (this.isCreate) {
      this.createEmptyForm()
    } else {
      this.createFormWithValues()
    }
  },
  methods: {
    ...mapActions(['estates/create', 'estates/update', 'estates/destroy']),
    notEmpty({ value }) {
      return value !== null && value !== undefined && value !== ''
    },
    goBack() {
      this.$router.go(-1)
    },
    async createEmptyForm() {
      if (!this.loaded) {
        await _wait()
        this.$nextTick(this.createEmptyForm)
        return
      }
      const data = this.fillableHeaders.reduce(
        (item, h) => ({
          ...item,
          [h.id]: getEmptyHeaderValue(h)
        }),
        {}
      )
      data._id = '#add'

      const validation = this.editHeaders.reduce(
        (validation, h) => ({
          ...validation,
          ...(h.req || h.main ? { [h.id]: ['required'] } : {})
        }),
        {}
      )
      this.form = new Form(data, validation)
      this.formTags = []
    },
    async createFormWithValues() {
      if (!this.loaded) {
        await _wait()
        this.$nextTick(this.createFormWithValues)
        return
      }

      const estate = this.estates.items.find(
        (estate) => estate._id === this.$route.params.id
      )

      const data = this.fillableHeaders.reduce(
        (item, h) => ({
          ...item,
          [h.id]:
            estate?.[h.id] === undefined ? getEmptyHeaderValue(h) : estate[h.id]
        }),
        {}
      )
      data._id = estate._id

      const validation = this.editHeaders.reduce(
        (validation, h) => ({
          ...validation,
          ...(h.req || h.main ? { [h.id]: ['required'] } : {})
        }),
        {}
      )

      this.form = new Form(data, validation)

      const formTagsOrder = estate._tagOrder || {}

      this.formTags = this.taggableHeaders
        .map((header) => ({
          header,
          value: estate?.[header.id]
        }))
        .sort((a, b) => {
          const idxA = formTagsOrder[a.header.id]
          const idxB = formTagsOrder[b.header.id]
          return idxB === undefined ? 1 : idxA === undefined ? -1 : idxA - idxB
        })
        .filter(this.notEmpty)
    },
    getEstateValue(heading) {
      if (!this.form) {
        return null
      }
      const data = this.form.data
      const v = data?.[heading.id] ?? getEmptyHeaderValue(heading)
      return v !== undefined && v !== null ? JSON.parse(JSON.stringify(v)) : v
    },
    setEstateValue(value, heading) {
      if (!this.form) {
        return null
      }
      this.$set(this.form, heading.id, value)
    },
    clearCurrentError(heading) {
      if (!this.form) {
        return null
      }
      this.form.clear(heading.id)
    },
    getTaggableDisplay({ header, value }) {
      switch (header.type) {
        case 'B':
          return value ? this.$t('bool.yes') : this.$t('bool.no')
        default:
          return value
      }
    },
    onAddTaggable(header) {
      if (header.type === 'B') {
        this.formTags.push({ header, value: true })
        return
      }
      this.tagInput = { header, value: '' }
      this.$nextTick(() => {
        const el = document.getElementById('taggable-input')
        if (el) {
          el.focus()
        }
      })
    },
    onEditTaggable(item) {
      this.tagInput = { ...item }
      this.$nextTick(() => {
        const el = document.getElementById('taggable-input')
        if (el) {
          el.focus()
          el.select()
        }
      })
    },
    saveTaggableAttribute() {
      if (!this.tagInput) {
        return
      }
      const items = this.formTags.slice(0)
      const idx = items.findIndex(
        (item) => item.header.id === this.tagInput.header.id
      )
      if (idx >= 0) {
        items.splice(idx, 1, this.tagInput)
      } else {
        items.push(this.tagInput)
      }
      this.formTags = items
      this.tagInput = null
    },
    removeTaggableAttribute() {
      if (!this.tagInput) {
        return
      }

      const formTags = this.formTags.slice(0)
      const idx = formTags.findIndex(
        (item) => item.header.id === this.tagInput.header.id
      )
      if (idx >= 0) {
        formTags.splice(idx, 1)
        this.formTags = formTags
      }
      this.tagInput = null
    },
    async onSaveItem() {
      if (!this.form) {
        return false
      }

      if (!this.form.validate()) {
        this.$toast('danger', 'general.formHasErrors')
        return false
      }
      const formData = this.form.data

      this.taggableHeaders.forEach((header) => {
        const taggable = this.formTags.find(
          (item) => item.header.id === header.id
        )
        formData[header.id] = taggable ? taggable.value : null
      })

      formData._tagOrder = this.formTags.reduce(
        (acc, { header }, idx) => ({ ...acc, [header.id]: idx }),
        {}
      )

      if (formData._id === '#add') {
        delete formData._id
        await this['estates/create'](formData)
      } else {
        await this['estates/update'](formData)
      }
      this.$router.push('/estates')
    },
    async onDelete() {
      if (this.isCreate) {
        return null
      }
      await this['estates/destroy']({ _id: this.estateId })
      this.$router.push('/estates')
    }
  }
}
</script>
